import './App.css';
import { Typography, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext } from "react";
import { AuthContext } from "./Approutes";
import Swal from 'sweetalert2';

function App() {
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const logout = async () => {
    // localStorage.removeItem('admin_auth_details');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    setAuth(false);
    await Swal.fire({
      icon: 'success',
      title: 'Oops...',
      text: 'Logout',
    })
    navigate('/');
  }
  return (
    <div className="App">
      <Typography variant="h2" sx={{ margin: '20px' }} gutterBottom>Admin Page</Typography>
      {!auth ? (
        <>
          <Stack spacing={5} alignItems="center" justifyContent="center" direction="column">
            <Button variant="contained" onClick={() => { navigate('/login') }} sx={{ width: '30%' }}>Login</Button>
          </Stack>
        </>
      ) : (
        <>
          <Stack spacing={5} alignItems="center" justifyContent="center" direction="column">
            <Button variant="contained" onClick={() => { navigate('/userworkshop') }} sx={{ width: '30%' }}>All Workshop</Button>
            <Button variant="contained" onClick={() => { navigate('/userevents') }} sx={{ width: '30%' }}>All Events</Button>
            <Button variant="contained" onClick={() => { logout() }} sx={{ width: '30%' }}>Logout</Button>
            <Button variant="contained" onClick={() => { navigate('/countworkshop') }} sx={{ width: '30%' }}>Count of Workshops</Button>
            <Button variant="contained" onClick={() => { navigate('/countevents') }} sx={{ width: '30%' }}>Count of Events</Button>
            <Button variant="contained" onClick={() => { navigate('/search') }} sx={{ width: '30%' }}>Search</Button>
          </Stack>
        </>
      )}

    </div>
  );
}

export default App;
