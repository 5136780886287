import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { AuthContext } from '../Approutes';

const UnAuthOnlyRoutes = () => {
    const { auth } = useContext(AuthContext);
    if (!auth) {
        return <Outlet />
    }
    else {
        return <Navigate to="/" replace />;
    }
}

export default UnAuthOnlyRoutes;