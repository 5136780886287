import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Approutes from './Approutes';
import { BrowserRouter } from 'react-router-dom';
// import { useEffect, useContext, createContext, useState } from "react";
// import { useLocation } from "react-router-dom";
// import ProtectedRoutes from "./routes/ProtectedRoutes";
// import UnAuthOnlyRoutes from "./routes/UnAuthOnlyRoutes";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Approutes />
    </BrowserRouter>
  </React.StrictMode>
);