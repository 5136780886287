import React from 'react';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import {
  Paper, TableRow, TableHead, TableContainer, TableBody, Table, TableCell, Typography, Button
  , Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox, FormGroup, Stack, Box
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const TableRegUserEvents = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getEvents = async () => {
      try {
        const result = await axios.post('https://api.admin.kurukshetraceg.org.in/api/data/getEvents', {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        });
        setEvents(result.data.message);
      }
      catch (err) {
        console.error(err.response.data);
      }
    }
    getEvents();
  }, [])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const addCheckList = (e) => {
    const isFound = checkEvent.some(element => {
      if (element === e.target.value) {
        return true;
      }
      return false;
    });

    if (isFound) {
      const newList = checkEvent.filter((item) => item !== e.target.value);
      setcheckEvent(newList);
    }
    else {
      const newList = checkEvent;
      newList.push(e.target.value);
      setcheckEvent(newList);
    }
  }
  const radioPayment = (e) => {
    if (payment === '' || payment !== e.target.value) {
      setpayment(e.target.value);
    }
    else {
      setpayment('');
    }
  }

  const radioRemarks = (e) => {
    if (remarks === '' || remarks !== e.target.value) {
      setremarks(e.target.value);
    }
    else {
      setremarks('');
    }
  }

  const radioCegian = (e) => {
    if (cegian === '' || cegian !== e.target.value) {
      setcegian(e.target.value);
    }
    else {
      setcegian('');
    }
  }
  const applyFilter = async () => {
    try {
      const result = await axios.post('https://api.admin.kurukshetraceg.org.in/api/data/filterEvents', {
        "events": checkEvent,
        "cegian": cegian,
        "payment": payment,
        "remarks": remarks,
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
        // auth: localStorage.getItem("admin_auth_details")
      });
      setEvents(result.data.message);
    }
    catch (err) {
      console.error(err.response.data);
    }
  }
  const [checkEvent, setcheckEvent] = useState([]);
  const [cegian, setcegian] = useState("");
  const [payment, setpayment] = useState("");
  const [remarks, setremarks] = useState("");
  return (
    <div>
      <Button variant="contained" onClick={() => { navigate('../') }} sx={{ borderRadius: '50%', height: '50px', width: '50px', margin: '20px' }}><BiArrowBack /></Button>
      <Typography variant="h2" sx={{ margin: '20px', textAlign: 'center' }} gutterBottom>
        All Events
      </Typography>
      <Typography variant="h5" sx={{ margin: '20px', textAlign: 'center' }} gutterBottom>
        Filter
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center" justifyContent="center" sx={{ margin: '20px' }} >
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Payment</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel value="Credit" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="Credit" />
            <FormControlLabel value="Failed" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="Failed" />
            <FormControlLabel value="null" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="N/A" />
            <FormControlLabel value="" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="All" />
          </RadioGroup>
        </FormControl>
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">CEGian</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel value="Yes" onClick={(e) => { radioCegian(e) }} control={<Radio />} label="Yes" />
              <FormControlLabel value="No" onClick={(e) => { radioCegian(e) }} control={<Radio />} label="No" />
              <FormControlLabel value=" " onClick={(e) => { radioCegian(e) }} control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>
        </div>
        <FormGroup>
          <FormLabel id="demo-radio-buttons-group-label">Events</FormLabel>
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="GENERALREGISTRATION" label="GenReg" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="PAPERPRESENTATION" label="PaperPres" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ONLINE" label="Investo" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="GODSPEED" label="Godspeed" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ROBOWARSEIGHT" label="Robowars8" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ROBOWARSFIFTEEN" label="Robowars15" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="DRONERACING" label="DroneRace" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="AEROMODELLING" label="Aeromodelling" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="NINJACODING" label="NinjaCoding" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ROWBOATICS" label="RowBoatics" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="HOVERCRAFT" label="Hovercraft" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="PACBOT" label="Pac-Bot" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="OPENQUIZ" label="OpenQuiz" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="CAPTURE" label="CTF" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="SERVICEDESIGNJAM" label="SDJ" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="IASPIRANT" label="iAspirant" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="INNOVPRESENTATION" label="InnovPres" />
        </FormGroup>
        <FormGroup>
          <FormLabel id="demo-radio-buttons-group-label">Remarks</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel value="Yes" onClick={(e) => { radioRemarks(e) }} control={<Radio />} label="Don't show workshop genreg" />
            <FormControlLabel value="No" onClick={(e) => { radioRemarks(e) }} control={<Radio />} label="Include workshop genreg" />
            <FormControlLabel value=" " onClick={(e) => { radioRemarks(e) }} control={<Radio />} label="Include all" />
          </RadioGroup>
        </FormGroup>
      </Stack>
      <Box textAlign='center'>
        <Button variant="contained" onClick={() => { applyFilter() }} alignItems="center" justifyContent="center" sx={{ width: '30%', margin: 'auto' }}>
          Apply Filter
        </Button>
      </Box>
      <TableContainer sx={{ width: '95%', margin: 'auto', marginTop: '20px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Purpose</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Payment ID</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Dependent</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Roll</StyledTableCell>
              <StyledTableCell>CEGian</StyledTableCell>
              <StyledTableCell>College</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row, id) => (
              <StyledTableRow key={id++}>
                <StyledTableCell>{row.purpose}</StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.payid}</StyledTableCell>
                <StyledTableCell>{row.amount}</StyledTableCell>
                <StyledTableCell>{row.dependent}</StyledTableCell>
                <StyledTableCell>{row.remarks}</StyledTableCell>
                <StyledTableCell>{row.firstname}</StyledTableCell>
                <StyledTableCell>{row.phone}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.roll}</StyledTableCell>
                <StyledTableCell>{String(row.cegian)}</StyledTableCell>
                <StyledTableCell>{row.college}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableRegUserEvents;