import React from 'react';
import {
    Typography, Button, CardContent, CardActions, Card, Box, TextField,
    IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from './Approutes';

import axios from 'axios';

const Login = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const login = async () => {
        try {
            const response = await axios.post(`https://api.admin.kurukshetraceg.org.in/api/data/login`, {
                username: username,
                password: password,
            });
            if (response.data.message === 'Success') {
                await Swal.fire({
                    icon: 'success',
                    title: 'Login Successful!',
                });
                setAuth(true);
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
                // localStorage.setItem('admin_auth_details', { username: username, password: password });
                navigate('../');
            }
        }
        catch (response) {
            await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.response.data.message,
            })
        }
    }
    return (
        <div>
            <Box sx={{ minWidth: 275, margin: 'auto', width: '60%', textAlign: 'center', marginTop: '30px' }}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h3" gutterBottom>
                            Login
                        </Typography>
                        <div>
                            <TextField onChange={(e) => { setusername(e.target.value) }} id="outlined-basic" label="Username" variant="outlined" />
                        </div>
                        <br></br>
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => { setpassword(e.target.value) }}
                                endAdornment={
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={() => { login() }} sx={{ width: '30%', margin: 'auto' }}>Login</Button>
                    </CardActions>
                </Card>
            </Box>
        </div>
    );
};

export default Login;