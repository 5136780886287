import React from 'react';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import {
  Paper, TableRow, TableHead, TableContainer, TableBody, Table, TableCell, Typography, Button
  , Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox, FormGroup, Stack, Box
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const TableRegUserWorkshop = () => {
  const [workshops, setWorkshops] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getWorkshops = async () => {
      try {
        const result = await axios.post('https://api.admin.kurukshetraceg.org.in/api/data/getWorkshop', {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        });
        setWorkshops(result.data.message);
      }
      catch (err) {
        console.error(err.response.data);
      }
    }
    getWorkshops();
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const addCheckList = (e) => {
    const isFound = checkWorkshop.some(element => {
      if (element === e.target.value) {
        return true;
      }
      return false;
    });

    if (isFound) {
      const newList = checkWorkshop.filter((item) => item !== e.target.value);
      setcheckWorkshop(newList);
    }
    else {
      const newList = checkWorkshop;
      newList.push(e.target.value);
      setcheckWorkshop(newList);
    }
  }
  const radioPayment = (e) => {
    if (payment === '' || payment !== e.target.value) {
      setpayment(e.target.value);
    }
    else {
      setpayment('');
    }
  }
  const radioCegian = (e) => {
    if (cegian === '' || cegian !== e.target.value) {
      setcegian(e.target.value);
    }
    else {
      setcegian('');
    }
  }
  const applyFilter = async () => {
    try {
      const result = await axios.post('https://api.admin.kurukshetraceg.org.in/api/data/filterWorkshops', {
        "events": checkWorkshop,
        "cegian": cegian,
        "payment": payment,
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
        // auth: localStorage.getItem("admin_auth_details")
      });
      setWorkshops(result.data.message);
    }
    catch (err) {
      console.error(err.response.data);
    }
  }
  const [checkWorkshop, setcheckWorkshop] = useState([]);
  const [cegian, setcegian] = useState("");
  const [payment, setpayment] = useState("");
  return (
    <div>
      <Button variant="contained" onClick={() => { navigate('../') }} sx={{ borderRadius: '50%', height: '50px', width: '50px', margin: '20px' }}><BiArrowBack /></Button>
      <Typography variant="h2" sx={{ margin: '20px', textAlign: 'center' }} gutterBottom>
        All Workshops
      </Typography>
      <Typography variant="h5" sx={{ margin: '20px', textAlign: 'center' }} gutterBottom>
        Filter
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center" justifyContent="center" sx={{ margin: '20px' }}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Payment</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel value="Credit" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="Credit" />
            <FormControlLabel value="Failed" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="Failed" />
            <FormControlLabel value="null" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="N/A" />
            <FormControlLabel value="" onClick={(e) => { radioPayment(e) }} control={<Radio />} label="All" />
          </RadioGroup>
        </FormControl>
        <div>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">CEGian</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel value="Yes" onClick={(e) => { radioCegian(e) }} control={<Radio />} label="Yes" />
              <FormControlLabel value="No" onClick={(e) => { radioCegian(e) }} control={<Radio />} label="No" />
              <FormControlLabel value=" " onClick={(e) => { radioCegian(e) }} control={<Radio />} label="All" />
            </RadioGroup>
          </FormControl>
        </div>
        <FormGroup>
          <FormLabel id="demo-radio-buttons-group-label">Workshop</FormLabel>
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="UIUXDESIGN" label="UI/UX" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ETHICALHACKING" label="EthicalHacking" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ETHICALHACKINGBULK" label="EthicalHacking - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ARDUINOBOTICS" label="ArduinoBotics" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="ARDUINOBOTICSBULK" label="ArduinoBotics - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="CRITICALTHINKING" label="CritThinking" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="CRITICALTHINKINGBULK" label="CritThinking - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="DEEPVISION" label="DeepVision" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="DEEPVISIONBULK" label="DeepVision - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="RCAIRCRAFT" label="RC-Aircraft" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="RCAIRCRAFTBULK" label="RC-Aircraft - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="FIREPOWER" label="BattleTank" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="FIREPOWERBULK" label="BattleTank - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="WIRELESSCOM" label="WirelessIoT" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="WIRELESSCOMBULK" label="WirelessIoT - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="DIGITALTWIN" label="DigiTwin" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="DIGITALTWINBULK" label="DigiTwin - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="VRXRFRONTIER" label="VR-XR" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="VRXRFRONTIERBULK" label="VR-XR - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="BRIDGEDESIGN" label="BridgeDesign" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="BRIDGEDESIGNBULK" label="BridgeDesign - Bulk" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="INDUSTRYSUPPLY" label="Industry4" />
          <FormControlLabel onClick={(e) => { addCheckList(e); }} control={<Checkbox />} value="INDUSTRYSUPPLYBULK" label="Industry4 - Bulk" />
        </FormGroup>
      </Stack>
      <Box textAlign='center'>
        <Button variant="contained" onClick={() => { applyFilter() }} alignItems="center" justifyContent="center" sx={{ width: '30%', margin: 'auto' }}>
          Apply Filter
        </Button>
      </Box>
      <TableContainer sx={{ width: '95%', margin: 'auto', marginTop: '20px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Purpose</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Payment ID</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Dependent</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Roll</StyledTableCell>
              <StyledTableCell>CEGian</StyledTableCell>
              <StyledTableCell>College</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workshops.map((row, id) => (
              <StyledTableRow key={id++}>
                <StyledTableCell>{row.purpose}</StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.payid}</StyledTableCell>
                <StyledTableCell>{row.amount}</StyledTableCell>
                <StyledTableCell>{row.dependent}</StyledTableCell>
                <StyledTableCell>{row.remarks}</StyledTableCell>
                <StyledTableCell>{row.firstname}</StyledTableCell>
                <StyledTableCell>{row.phone}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.roll}</StyledTableCell>
                <StyledTableCell>{String(row.cegian)}</StyledTableCell>
                <StyledTableCell>{row.college}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableRegUserWorkshop;