import React from 'react';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import {
  Paper, TableRow, TableHead, TableContainer, TableBody, Table, TableCell, Typography, Button
  , Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox, FormGroup, Stack, Box, TextField
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const Search = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const [search, setsearch] = useState("");
  const applySearch = async () => {
    const result = await axios.post('https://api.admin.kurukshetraceg.org.in/api/data/search', {
        search:search
      });
      console.log(result.data.message);
      setEvents(result.data.message);
  }
  return (
    <div>
      <Button variant="contained" onClick={() => { navigate('../') }} sx={{ borderRadius: '50%', height: '50px', width: '50px', margin: '20px' }}><BiArrowBack /></Button>
      <Typography variant="h2" sx={{ margin: '20px', textAlign: 'center' }} gutterBottom>
        Search
      </Typography>
      <Box textAlign='center'>
      <TextField onChange={(e)=>{setsearch(e.target.value)}} sx={{margin:"20px"}}  id="outlined-basic" label="Search" variant="outlined"></TextField>
      </Box>
      <Box textAlign='center'>
        <Button variant="contained" onClick={() => { applySearch() }} alignItems="center" justifyContent="center" sx={{ width: '30%', margin: 'auto' }}>
          Search
        </Button>
      </Box>
      <TableContainer sx={{ width: '95%', margin: 'auto', marginTop: '20px' }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Purpose</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Payment ID</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
              <StyledTableCell>Dependent</StyledTableCell>
              <StyledTableCell>Remarks</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Roll</StyledTableCell>
              <StyledTableCell>CEGian</StyledTableCell>
              <StyledTableCell>College</StyledTableCell>
              <StyledTableCell>MKid</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row, id) => (
              <StyledTableRow key={id++}>
                <StyledTableCell>{row.purpose}</StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
                <StyledTableCell>{row.payid}</StyledTableCell>
                <StyledTableCell>{row.amount}</StyledTableCell>
                <StyledTableCell>{row.dependent}</StyledTableCell>
                <StyledTableCell>{row.remarks}</StyledTableCell>
                <StyledTableCell>{row.firstname}</StyledTableCell>
                <StyledTableCell>{row.phone}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.roll}</StyledTableCell>
                <StyledTableCell>{String(row.cegian)}</StyledTableCell>
                <StyledTableCell>{row.college}</StyledTableCell>
                <StyledTableCell>{row.mkid}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Search;