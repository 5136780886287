import React from 'react';
import App from './App';
import { Routes, Route } from 'react-router-dom';
import TableRegUserEvents from './TableRegUserEvents';
import TableRegUserWorkshop from './TableRegUserWorkshop';
import TableCountEvents from './TableCountEvents';
import TableCountWorkshop from './TableCountWorkshop';
import Search from './Search';
import Login from './Login';
import { useEffect, createContext, useState } from "react";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import UnAuthOnlyRoutes from "./routes/UnAuthOnlyRoutes";
export const AuthContext = createContext();

const Approutes = () => {
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        // if (localStorage.getItem("admin_auth_details")) {
        if (localStorage.getItem("username") && localStorage.getItem("password")) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, []);

    return (
        <div>
            <AuthContext.Provider value={{ auth, setAuth }}>
                <Routes>
                    <Route path="/" element={<App />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route path="userevents" element={<TableRegUserEvents />} />
                        <Route path="userworkshop" element={<TableRegUserWorkshop />} />
                        <Route path="countevents" element={<TableCountEvents />} />
                        <Route path="countworkshop" element={<TableCountWorkshop />} />
                        <Route path="search" element={<Search/>} />
                    </Route>
                    <Route element={<UnAuthOnlyRoutes />}>
                        <Route path="login" element={<Login />} />
                    </Route>
                </Routes>
            </AuthContext.Provider>
        </div>
    );
};

export default Approutes;